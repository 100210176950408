<template>
  <base-section
    id="affiliates"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading
        title="Become our Partner"
        subtitle="You want to join us on our journey?"
      />
      <v-row>
        <v-col
          v-for="card of cards"
          :key="card.title"
          cols="12"
          sm="4"
          md="4"
        >
          <base-info-card
            align="center"
            v-bind="card"
            color="accent"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="text-center"
          cols="12"
        >
          <base-btn
            :block="$vuetify.breakpoint.smAndDown"
            color="primary"
            href="mailto:contact@aptamimetics.com?subject=Become%20a%20Partner"
            large
            target="_blank"
          >
            Contact Us
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPartners',

    data: () => ({
      cards: [
        {
          icon: 'fal fa-hand-holding-usd',
          title: 'Investor',
          text: 'Invest in our growing company and take full advantage of our financial development.',
        },
        {
          icon: 'fal fa-user-tie',
          title: 'Customer',
          text: 'Get your hands on one of our superior products. Or just let us create a new one for you!',
        },
        {
          icon: 'fal fa-hands-helping',
          title: 'Colleague',
          text: 'Want to join the team? Contact us for our current openings.',
        },
      ],
    }),
  }
</script>
